import React from 'react';

export const onClientEntry = () => {
  if (typeof window !== 'undefined') {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    script.async = true;
    document.body.appendChild(script);
  }
};
